import Container from 'react-bootstrap/Container';

export default function Footer() {
  return (
    <Container>
      <footer>
        <h1>OWNER</h1>
        </footer>
      </Container>
  )
}
